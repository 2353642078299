<template>
  <section class="homepage-landing">
    <section class="homepage-landing-hero">
      <div class="content">
        <span class="bg-text thin c-white"> The Miilk analysis </span>

        <div class="left">
          <template v-if="level1 != null">
            <div class="text-wrapper sticky">
              <div class="sticky-wrapper">
                <ul
                    :class="{ 'small-margin': level1.collections.length == 0 }"
                    class="tags"
                    v-if="level1.hero_tags.length > 0"
                >
                  <li class="collection" v-if="level1.collections.length > 0">
                    <a
                        :href="`/collections/${level1.hero_collection.id}`"
                        class="collection-linkage no-deco ellipsis one"
                    >
                      {{ level1.hero_collection.name }}
                    </a>
                  </li>

                  <template v-else>
                    <li
                        class="tag"
                        v-for="tag in level1.hero_tags"
                        :key="`l1tag-${tag.id}`"
                    >
                      <a :href="`/tags/${tag.id}`" class="tag-linkage">
                        {{ tag.name }}
                      </a>
                    </li>
                  </template>
                </ul>

                <a :href="articleUrl(level1)">
                  <h2
                      class="title label-middle-title bold c-white ellipsis three"
                  >
                    {{ level1.title }}
                  </h2>
                </a>

                <a :href="articleUrl(level1)" class="no-deco">
                  <p
                      class="description label-middle-description c-white ellipsis four"
                  >
                    {{ level1.first_text }}
                    <!--                <%= CGI.unescapeHTML(strip_tags(level1.first_text)) %>-->
                  </p>
                </a>

                <p class="author label-xsmall c-white">
                  <author-name :article="level1" />
                  <span class="date">{{ level1.pdt_published_at }} </span>
                </p>
              </div>
            </div>

            <div class="image-wrapper">
              <a :href="articleUrl(level1)">
                <div class="image-box float fit-cover">
                  <picture>
                    <source
                        :data-srcset="`${heroImageUrl(level1, 750)} 375w`"
                        media="(max-width: 375px)"
                        type="image/webp"
                    />
                    <source
                        :data-srcset="
                        `${heroImageUrl(level1, 375)} 1x, ${heroImageUrl(
                          level1,
                          750
                        )} 2x`
                      "
                        type="image/webp"
                    />
                    <source
                        :srcset="`${heroImageUrl(level1, 750)} 375w`"
                        media="(max-width: 375px)"
                        type="image/jpg"
                    />
                    <!-- type="image/타입" 부분에 이미지 타입을 적어줘야합니다. -->
                    <img
                        :src="heroImageUrl(level1, 640)"
                        :srcset="
                        heroImageUrl(level1, 1280) +
                          ' 2x, ' +
                          heroImageUrl(level1, 2560) +
                          ' 4x'
                      "
                        sizes="auto"
                        :alt="level1.title"
                        class=""
                    />
                  </picture>
                </div>
                <img
                    src="https://assets.themiilk.com/commons/blank.png"
                    alt=""
                    class="blank-image"
                />
              </a>
            </div>
          </template>
        </div>

        <div class="right">
          <template v-if="level2 != null">
            <div class="image-wrapper">
              <a :href="articleUrl(level2)">
                <div class="image-box float fit-cover">
                  <picture>
                    <source
                        :data-srcset="`${heroImageUrl(level2, 750)} 375w`"
                        media="(max-width: 375px)"
                        type="image/webp"
                    />
                    <source
                        :data-srcset="
                        `${heroImageUrl(level2, 375)} 1x, ${heroImageUrl(
                          level2,
                          750
                        )} 2x`
                      "
                        type="image/webp"
                    />
                    <source
                        :srcset="`${heroImageUrl(level2, 750)} 375w`"
                        media="(max-width: 375px)"
                        type="image/jpg"
                    />
                    <!-- type="image/타입" 부분에 이미지 타입을 적어줘야합니다. -->
                    <img
                        :src="heroImageUrl(level2, 380)"
                        :srcset="
                        heroImageUrl(level2, 760) +
                          ' 2x, ' +
                          heroImageUrl(level2, 1520) +
                          ' 4x'
                      "
                        sizes="auto"
                        :alt="level2.title"
                        class=""
                    />
                  </picture>
                </div>
                <img
                    src="https://assets.themiilk.com/commons/blank.png"
                    alt=""
                    class="blank-image"
                />
              </a>
            </div>

            <div class="text-wrapper">
              <ul class="tags small-margin" v-if="level2.hero_tags.length > 0">
                <li class="collection" v-if="level2.collections.length > 0">
                  <a
                      :href="`/collections/${level2.hero_collection.id}`"
                      class="collection-linkage no-deco ellipsis one"
                  >
                    {{ level2.hero_collection.name }}
                  </a>
                </li>

                <template v-else>
                  <li
                      class="tag"
                      v-for="tag in level2.hero_tags"
                      :key="`l2tag-${tag.id}`"
                  >
                    <a :href="`/tags/${tag.id}`" class="tag-linkage">
                      {{ tag.name }}
                    </a>
                  </li>
                </template>
              </ul>

              <a :href="articleUrl(level2)">
                <h2 class="title label-small-title bold c-white ellipsis two">
                  {{ level2.title }}
                </h2>
              </a>

              <p class="author label-xsmall c-white">
                <author-name :article="level2" />
                <span class="date"> {{ level2.pdt_published_at }} </span>
              </p>
            </div>
          </template>
        </div>
      </div>
    </section>

    <div class="content">
      <div class="news-container type2 sub-section">
        <div class="news-wrapper">
          <div class="news-head">
            <span class="label-small bold head-title">
              Latest Brief
            </span>

            <a href="/topics/all" class="button button-arrow label-small">
              All Articles
              <div class="icon-arrow">
                <div class="icon"></div>
              </div>
            </a>
          </div>

          <div class="news-items lastest-brief">
            <div
                class="news-item"
                v-for="level3Article in level3"
                :key="level3Article.id"
            >
              <div class="image-wrapper">
                <a :href="articleUrl(level3Article)">
                  <div class="image-box float fit-cover small-radius">
                    <picture>
                      <source
                          :data-srcset="
                          `${heroImageUrl(level3Article, 750)} 375w`
                        "
                          media="(max-width: 375px)"
                          type="image/webp"
                      />
                      <source
                          :data-srcset="
                          `${heroImageUrl(
                            level3Article,
                            375
                          )} 1x, ${heroImageUrl(level3Article, 750)} 2x`
                        "
                          type="image/webp"
                      />
                      <source
                          :srcset="`${heroImageUrl(level3Article, 750)} 375w`"
                          media="(max-width: 375px)"
                          type="image/jpg"
                      />
                      <!-- type="image/타입" 부분에 이미지 타입을 적어줘야합니다. -->
                      <img
                          :src="heroImageUrl(level3Article, 300)"
                          :srcset="
                          heroImageUrl(level3Article, 600) +
                            ' 2x, ' +
                            heroImageUrl(level3Article, 1200) +
                            ' 4x'
                        "
                          sizes="auto"
                          :alt="level3Article.title"
                          class=""
                      />
                    </picture>
                  </div>
                  <img
                      src="https://assets.themiilk.com/commons/blank.png"
                      alt=""
                      class="blank-image"
                  />
                </a>
              </div>

              <div class="text-wrapper">
                <ul
                    class="tags small-margin"
                    v-if="level3Article.hero_tags.length > 0"
                >
                  <li
                      class="collection"
                      v-if="level3Article.collections.length > 0"
                  >
                    <a
                        :href="`/collections/${level3Article.hero_collection.id}`"
                        class="collection-linkage no-deco ellipsis one"
                    >
                      {{ level3Article.hero_collection.name }}
                    </a>
                  </li>
                  <template v-else>
                    <li
                        class="tag"
                        v-for="tag in level3Article.hero_tags"
                        :key="`l3tag-${level3Article.id}-${tag.id}`"
                    >
                      <a :href="`/tags/${tag.id}`" class="tag-linkage">
                        {{ tag.name }}
                      </a>
                    </li>
                  </template>
                </ul>

                <a :href="articleUrl(level3Article)">
                  <h2 class="title label-description bold ellipsis two">
                    {{ level3Article.title }}
                  </h2>
                </a>

                <p class="author label-xsmall ellipsis one">
                  <author-name :article="level3Article" />
                  <span class="date">
                    {{ level3Article.pdt_published_at }}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="news-wrapper special">
          <div class="cta-head">
            <span class="label-small bold head-title">The Miilk Special</span>
          </div>

          <div class="cta-side">
            <a href="https://themiilk.com/articles/a48648a49"
               class="cta-item no-deco cta-banner-mwc mwc2"
            ></a>
            <a href="https://themiilk.com/articles/ad1b864dc"
               class="cta-item no-deco cta-banner-mwc"
            >
            </a>
          </div>
        </div>
      </div>

      <div class="news-container split type1 video-section">
        <div class="news-head">
          <span class="label-small bold head-title">
            Video
          </span>
        </div>

        <div class="news-wrapper">
          <div class="news-item main-news" v-if="level1Video != null">
            <div class="image-wrapper">
              <a :href="`/videos/${level1Video.id}`">
                <div
                    class="image-box"
                    :style="
                    `background-size: cover; background-image: url('${level1Video.thumbnail_url}')`
                  "
                >
                  <img
                      src="https://assets.themiilk.com/commons/blank.png"
                      class="blank-image"
                  />
                </div>
              </a>
            </div>

            <div class="text-wrapper sticky">
              <div class="sticky-wrapper">
                <a :href="`/videos/${level1Video.id}`">
                  <h2 class="title label-middle-title bold ellipsis three">
                    {{ level1Video.title }}
                  </h2>
                </a>

                <a :href="`/videos/${level1Video.id}`" class="no-deco">
                  <p class="description label-middle-description ellipsis five">
                    {{ level1Video.description }}
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div
              class="news-item vertical mobile-middle"
              v-if="level2Video != null"
          >
            <div class="image-wrapper">
              <a :href="`/videos/${level2Video.id}`">
                <div
                    class="image-box"
                    :style="
                    `background-size: cover; background-image: url('${level2Video.thumbnail_url}')`
                  "
                >
                  <img
                      src="https://assets.themiilk.com/commons/blank.png"
                      class="blank-image"
                  />
                </div>
              </a>
            </div>

            <div class="text-wrapper">
              <a :href="`/videos/${level2Video.id}`">
                <h2
                    class="title label-small-title bold ellipsis two mobile-three"
                >
                  {{ level2Video.title }}
                </h2>
              </a>

              <a :href="`/videos/${level2Video.id}`" class="no-deco">
                <p
                    class="description label-middle-description ellipsis one tablet-show"
                >
                  {{ level2Video.description }}
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>

      <template v-if="eventType != null">
        <div class="news-container split type-event event-section">
          <div class="news-head">
            <span class="label-small bold head-title"> The Miilk Event </span>
          </div>

          <div class="news-wrapper" v-if="eventType == 'event'">
            <div class="news-item main-news">
              <div class="image-wrapper">
                <a :href="`/events/${mainEvent.id}`">
                  <div
                      class="image-box"
                      :style="
                      `background-size: cover; background-image: url('${mainEvent.hero_image_url}')`
                    "
                  >
                    <img
                        src="https://assets.themiilk.com/commons/blank.png"
                        class="blank-image"
                    />
                  </div>
                </a>
              </div>

              <div class="text-wrapper sticky">
                <div class="sticky-wrapper">
                  <a :href="`/events/${mainEvent.id}`">
                    <h2 class="title label-middle-title bold ellipsis three">
                      {{ mainEvent.title }}
                    </h2>
                  </a>

                  <a :href="`/events/${mainEvent.id}`" class="no-deco">
                    <p
                        class="description label-middle-description ellipsis five"
                    >
                      {{ mainEvent.description }}
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="news-wrapper" v-else-if="eventType == 'report'">
            <div class="news-item main-news">
              <div class="image-wrapper">
                <a :href="`/reports/${mainEvent.id}`">
                  <div
                      class="image-box"
                      :style="
                      `background-size: cover; background-image: url('${mainEvent.main_image_url}')`
                    "
                  >
                    <img
                        src="https://assets.themiilk.com/commons/blank.png"
                        class="blank-image"
                    />
                  </div>
                </a>
              </div>

              <div class="text-wrapper sticky">
                <div class="sticky-wrapper">
                  <a :href="`/reports/${mainEvent.id}`">
                    <h2 class="title label-middle-title bold ellipsis three">
                      {{ mainEvent.title | stripHTML }}
                    </h2>
                  </a>

                  <a :href="`/reports/${mainEvent.id}`" class="no-deco">
                    <p
                        class="description label-middle-description ellipsis five"
                    >
                      {{ mainEvent.summary | stripHTML }}
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="news-container split type1 business-insight-section">
        <div class="news-head">
          <span class="label-small bold head-title">
            Business Insight
          </span>
        </div>

        <div class="news-wrapper">
          <div class="news-item main-news" v-if="investingArticle != null">
            <div class="image-wrapper">
              <a :href="articleUrl(investingArticle)">
                <div class="image-box float fix-cover">
                  <picture>
                    <source
                        :data-srcset="
                        `${heroImageUrl(investingArticle, 750)} 375w`
                      "
                        media="(max-width: 375px)"
                        type="image/webp"
                    />
                    <source
                        :data-srcset="
                        `${heroImageUrl(
                          investingArticle,
                          375
                        )} 1x, ${heroImageUrl(investingArticle, 750)} 2x`
                      "
                        type="image/webp"
                    />
                    <source
                        :srcset="`${heroImageUrl(investingArticle, 750)} 375w`"
                        media="(max-width: 375px)"
                        type="image/jpg"
                    />
                    <img
                        :src="heroImageUrl(investingArticle, 640)"
                        :srcset="
                        heroImageUrl(investingArticle, 1280) +
                          ' 2x, ' +
                          heroImageUrl(investingArticle, 2560) +
                          ' 4x'
                      "
                        :alt="investingArticle.title"
                        class=""
                    />
                  </picture>
                </div>
              </a>
              <img
                  src="https://assets.themiilk.com/commons/blank.png"
                  alt=""
                  class="blank-image"
              />
            </div>

            <div class="text-wrapper">
              <ul
                  class="tags small-margin"
                  v-if="investingArticle.hero_tags.length > 0"
              >
                <li
                    class="tag"
                    v-for="tag in investingArticle.hero_tags"
                    :key="`investingTag-${tag.id}`"
                >
                  <a :href="`/tags/${tag.id}`" class="tag-linkage">
                    {{ tag.name }}
                  </a>
                </li>
              </ul>

              <a :href="articleUrl(investingArticle)">
                <h2 class="title label-middle-title bold ellipsis three">
                  {{ investingArticle.title }}
                </h2>
              </a>

              <a :href="articleUrl(investingArticle)" class="no-deco">
                <p class="description label-middle-description ellipsis five">
                  {{ investingArticle.first_text }}
                </p>
              </a>

              <p class="author label-xsmall">
                <author-name :article="investingArticle" />
                <span class="date">
                  {{ investingArticle.pdt_published_at }}
                </span>
              </p>
            </div>
          </div>

          <div class="news-item vertical" v-if="investingArticle2 != null">
            <div class="image-wrapper">
              <a :href="articleUrl(investingArticle2)">
                <div class="image-box float fit-cover">
                  <picture>
                    <source
                        :data-srcset="
                        `${heroImageUrl(investingArticle2, 750)} 375w`
                      "
                        media="(max-width: 375px)"
                        type="image/webp"
                    />
                    <source
                        :data-srcset="
                        `${heroImageUrl(
                          investingArticle2,
                          375
                        )} 1x, ${heroImageUrl(investingArticle2, 750)} 2x`
                      "
                        type="image/webp"
                    />
                    <source
                        :srcset="`${heroImageUrl(investingArticle2, 750)} 375w`"
                        media="(max-width: 375px)"
                        type="image/jpg"
                    />
                    <img
                        :src="heroImageUrl(investingArticle2, 640)"
                        :srcset="
                        heroImageUrl(investingArticle2, 1280) +
                          ' 2x, ' +
                          heroImageUrl(investingArticle2, 2560) +
                          ' 4x'
                      "
                        sizes="auto"
                        :alt="investingArticle2.title"
                        class=""
                    />
                  </picture>
                </div>
              </a>
              <img
                  src="https://assets.themiilk.com/commons/blank.png"
                  alt=""
                  class="blank-image"
              />
            </div>

            <div class="text-wrapper">
              <ul
                  class="tags small-margin"
                  v-if="investingArticle2.hero_tags.length > 0"
              >
                <li
                    class="tag"
                    v-for="tag in investingArticle2.hero_tags"
                    :key="`investingTag2-${tag.id}`"
                >
                  <a :href="`/tags/${tag.id}`" class="tag-linkage">
                    {{ tag.name }}
                  </a>
                </li>
              </ul>

              <a :href="articleUrl(investingArticle2)">
                <h2 class="title label-small-title bold ellipsis two">
                  {{ investingArticle2.title }}
                </h2>
              </a>

              <!--
              <%= link_to article_url(investing_article2), class: 'no-deco' do %>
                <p class="description label-middle-description ellipsis two">
                  <%= CGI.unescapeHTML(strip_tags(investing_article2.first_text)) %>
                </p>
              <% end %>
              -->

              <p class="author label-xsmall">
                <author-name :article="investingArticle2" />
                <span class="date">
                  {{ investingArticle2.pdt_published_at }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <email-layer-popup v-if="setLayerPopup" @closeModal="closeModal" />

    <!--    <% if @current_user.present? && (@current_user.email.blank? || @current_user.email == 'undefined') %>-->
    <!--    <%= render partial: '/service/commons/layer_popup_email' %>-->
    <!--    <% end %>-->
  </section>
</template>

<script>
import EmailLayerPopup from "./email_popup";
import AuthorName from "../../commons/author_name";

export default {
  props: {},
  data() {
    return {
      user: this.getUser(),
      level1: null,
      level2: null,
      level3: [],
      level1Video: null,
      level2Video: null,

      eventType: null,
      mainEvent: null,

      investingArticle: null,
      investingArticle2: null,

      showLayerPopup: true,
    };
  },
  computed: {
    setLayerPopup() {
      if (
          this.user == null ||
          (this.user.email != null &&
              this.user.email.length > 0 &&
              this.user.email != "undefined")
      ) {
        return false;
      }
      return this.showLayerPopup;
    },
  },
  created() {
    axios.get("/api/articles/main").then((res) => {
      console.log(res.data);
      this.level1 = res.data.level1;
      this.level2 = res.data.level2;
      this.level3 = res.data.level3;
      this.investingArticle = res.data.investingArticle;
      this.investingArticle2 = res.data.investingArticle2;
    });

    axios.get("/api/videos/main").then((res) => {
      this.level1Video = res.data.video1;
      this.level2Video = res.data.video2;
    });

    axios.get("/api/events/main").then((res) => {
      this.eventType = res.data.event_type;
      this.mainEvent = res.data.event;
    });
  },
  methods: {
    getUser() {
      const userInfo = localStorage.getItem("miilkUser");
      if (userInfo != null && userInfo.length > 0) {
        return JSON.parse(userInfo);
      }

      return null;
    },
    closeModal() {
      this.showLayerPopup = false;
    },
  },
  components: {
    EmailLayerPopup,
    AuthorName,
  },
};
</script>
